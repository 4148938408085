import { useMemo } from 'react';

import Flag from 'react-world-flags';

import { useIntl } from 'localization';
import { TOption } from 'ui/src/molecules/Dropdown/Dropdown';

import { useGetCountries } from './core/store/useGetCountries';

const useGetCountryOptions = () => {
  const intl = useIntl();

  const { data: countries, isLoading } = useGetCountries();
  const loc = useMemo(() => intl.locale.split('-')[0], [intl.locale]);
  let countryOptions: TOption[] = [];

  if (!isLoading) {
    countryOptions = Object.entries(countries || [])
      .map(([, value]) => ({
        icon: (
          <div className="w-6 h-6 flex items-center justify-center">
            <Flag
              className="rounded-sm object-cover"
              style={{ width: '18px', height: '14px' }}
              code={value.countryCode}
            />
          </div>
        ),
        label: intl.formatMessage({ id: `cc.${value.countryCode}` }),
        value: value.countryCode,
      }))
      .sort((a, b) => a.label.localeCompare(b.label, loc));
  }

  return { countryOptions, isLoading };
};

export default useGetCountryOptions;
