export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  JSON: { input: any; output: any };
};

export interface Action {
  __typename?: 'Action';
  config: Scalars['JSON']['output'];
  name: Scalars['String']['output'];
}

export interface ActionInput {
  config: Scalars['JSON']['input'];
  name: Scalars['String']['input'];
}

export interface Address {
  __typename?: 'Address';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  contactName: Scalars['String']['output'];
  contactPhone: Scalars['String']['output'];
  country: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  postcode: Scalars['String']['output'];
  state: Maybe<Scalars['String']['output']>;
}

export interface AddressInput {
  address: Scalars['String']['input'];
  city: InputMaybe<Scalars['String']['input']>;
  contactName: InputMaybe<Scalars['String']['input']>;
  contactPhone: InputMaybe<Scalars['String']['input']>;
  country: InputMaybe<Scalars['String']['input']>;
  countryCode: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  postcode: InputMaybe<Scalars['String']['input']>;
  state: InputMaybe<Scalars['String']['input']>;
}

export interface ApplySurcharge {
  __typename?: 'ApplySurcharge';
  applyOnAllShippingMethods: Scalars['Boolean']['output'];
  surcharges: Array<Maybe<Scalars['JSON']['output']>>;
}

export interface ApplySurchargeInput {
  applyOnAllShippingMethods: Scalars['Boolean']['input'];
  surcharges: Array<InputMaybe<Scalars['JSON']['input']>>;
}

export interface AvailableIntegration {
  __typename?: 'AvailableIntegration';
  docs: Maybe<IntegrationDocs>;
  featured: Maybe<Scalars['Boolean']['output']>;
  integration: IntegrationType;
  isMock: Maybe<Scalars['Boolean']['output']>;
  isThirdParty: Maybe<Scalars['Boolean']['output']>;
  logo: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  needsUpgrade: Scalars['Boolean']['output'];
  plugin: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
}

export interface BasketConfig {
  __typename?: 'BasketConfig';
  enabled: Maybe<Scalars['Boolean']['output']>;
  maxBasketsPerPickingList: Maybe<Scalars['Int']['output']>;
  maxProductsPerBasket: Maybe<Scalars['Int']['output']>;
}

export interface BasketInput {
  enabled: InputMaybe<Scalars['Boolean']['input']>;
  maxBasketsPerPickingList: InputMaybe<Scalars['Int']['input']>;
  maxProductsPerBasket: InputMaybe<Scalars['Int']['input']>;
}

export interface Brand {
  __typename?: 'Brand';
  brandUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logo: Maybe<Scalars['String']['output']>;
  slug: Maybe<Scalars['String']['output']>;
  trackingSlug: Maybe<Scalars['String']['output']>;
  type: BrandType;
}

export enum BrandType {
  Custom = 'custom',
  Default = 'default',
}

export interface Condition {
  __typename?: 'Condition';
  operator: Logical;
  rules: Array<Maybe<Rule>>;
}

export interface ConditionInput {
  operator: Logical;
  rules: Array<InputMaybe<RuleInput>>;
}

export interface Courier {
  __typename?: 'Courier';
  active: Scalars['Boolean']['output'];
  alias: Maybe<Scalars['String']['output']>;
  characteristics: Scalars['JSON']['output'];
  contact_info: Maybe<Scalars['JSON']['output']>;
  country: Maybe<Scalars['String']['output']>;
  courierAlias: Maybe<Scalars['String']['output']>;
  courierName: Maybe<Scalars['String']['output']>;
  credentials: Maybe<Scalars['JSON']['output']>;
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  is_blocked: Maybe<Scalars['Boolean']['output']>;
  is_default: Scalars['Boolean']['output'];
  original_characteristics: Maybe<Scalars['JSON']['output']>;
  personalMethodCodes: Maybe<Scalars['JSON']['output']>;
  thirdPartyCourier: Scalars['Boolean']['output'];
  valid_credentials: Maybe<Scalars['Boolean']['output']>;
}

export interface CourierList {
  __typename?: 'CourierList';
  active: Scalars['Boolean']['output'];
  courierAlias: Scalars['String']['output'];
  courierName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  thirdPartyCourier: Scalars['Boolean']['output'];
}

export interface CourierMethods {
  __typename?: 'CourierMethods';
  alias: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
}

export interface CourierSchemaDetails {
  __typename?: 'CourierSchemaDetails';
  additionalSettings: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  alias: Scalars['String']['output'];
  allowed_countries: Array<Scalars['String']['output']>;
  credentials: Array<Maybe<Creds>>;
  logo: Scalars['String']['output'];
  name: Scalars['String']['output'];
  settings: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  smallLogo: Maybe<Scalars['String']['output']>;
  surcharges: Maybe<Array<Scalars['String']['output']>>;
}

export interface CourierStats {
  __typename?: 'CourierStats';
  courierName: Maybe<Scalars['String']['output']>;
  customerExperience: Maybe<Scalars['Float']['output']>;
  deliveryAttempts: Maybe<Scalars['Float']['output']>;
  domestic: Maybe<Scalars['Float']['output']>;
  incidents: Maybe<Scalars['Float']['output']>;
  international: Maybe<Scalars['Float']['output']>;
  requestRate: Maybe<Scalars['Boolean']['output']>;
}

export interface CourierType {
  __typename?: 'CourierType';
  alias: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  integration: Scalars['String']['output'];
  isPickupRequestImplemented: Maybe<Scalars['Boolean']['output']>;
  logo: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  smallLogo: Maybe<Scalars['String']['output']>;
}

export interface CourierTypeWithStats {
  __typename?: 'CourierTypeWithStats';
  alias: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  integration: Scalars['String']['output'];
  name: Scalars['String']['output'];
  onlyTracking: Maybe<Scalars['Boolean']['output']>;
  statistics: Maybe<CourierStats>;
}

export interface CreateCustomBrandInput {
  slug: InputMaybe<Scalars['String']['input']>;
}

export interface Creds {
  __typename?: 'Creds';
  field: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  translations: Translation;
  type: Scalars['String']['output'];
}

export interface CustomerMailNotificationsKpi {
  __typename?: 'CustomerMailNotificationsKpi';
  clickRate: Maybe<Scalars['Int']['output']>;
  clicked: Maybe<Scalars['Int']['output']>;
  delivered: Maybe<Scalars['Int']['output']>;
  failed: Maybe<Scalars['Int']['output']>;
  failedRate: Maybe<Scalars['Int']['output']>;
  openRate: Maybe<Scalars['Int']['output']>;
  opened: Maybe<Scalars['Int']['output']>;
  sent: Maybe<Scalars['Int']['output']>;
  total: Maybe<Scalars['Int']['output']>;
  totalSent: Maybe<Scalars['Int']['output']>;
}

export interface CustomerMailNotificationsKpiInput {
  cache: InputMaybe<Scalars['Boolean']['input']>;
  fromDate: InputMaybe<Scalars['String']['input']>;
  toDate: InputMaybe<Scalars['String']['input']>;
}

export interface Features {
  __typename?: 'Features';
  canSeePickingList: Scalars['Boolean']['output'];
  skipAnalytics: Scalars['Boolean']['output'];
}

export interface FulfillmentStatistics {
  __typename?: 'FulfillmentStatistics';
  averageCartValue: Scalars['Float']['output'];
  exchangeOrders: Scalars['Int']['output'];
  processedOrders: Scalars['Int']['output'];
  receivedOrders: Scalars['Int']['output'];
  returnOrders: Scalars['Int']['output'];
  returnRate: Scalars['Float']['output'];
  totalSales: Scalars['Float']['output'];
}

export enum GroupProductsBy {
  Barcode = 'barcode',
  Sku = 'sku',
}

export interface Integration {
  __typename?: 'Integration';
  alias: Maybe<Scalars['String']['output']>;
  clientId: Maybe<Scalars['String']['output']>;
  config: Maybe<Scalars['JSON']['output']>;
  deleted: Scalars['Boolean']['output'];
  endpoint: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  isBlocked: Scalars['Boolean']['output'];
  lastConnection: Maybe<Scalars['String']['output']>;
  store: Scalars['ID']['output'];
  type: IntegrationType;
}

export interface IntegrationDocs {
  __typename?: 'IntegrationDocs';
  de: Maybe<Scalars['String']['output']>;
  en: Maybe<Scalars['String']['output']>;
  es: Maybe<Scalars['String']['output']>;
  pl: Maybe<Scalars['String']['output']>;
  pt: Maybe<Scalars['String']['output']>;
}

export enum IntegrationType {
  LogiCommerce = 'LogiCommerce',
  Manomano = 'Manomano',
  Vivino = 'Vivino',
  Voog = 'Voog',
  Wix = 'Wix',
  AliExpress = 'aliExpress',
  Allegro = 'allegro',
  Amazon = 'amazon',
  AmazonApp = 'amazonApp',
  Api = 'api',
  BigCommerce = 'bigCommerce',
  Ebay = 'ebay',
  Ecwid = 'ecwid',
  Epages = 'epages',
  Etsy = 'etsy',
  Katana = 'katana',
  Magento = 'magento',
  Magento2 = 'magento2',
  Odoo = 'odoo',
  OpenCart2 = 'openCart2',
  OpenCart3 = 'openCart3',
  Prestashop = 'prestashop',
  Pulpo = 'pulpo',
  Salesforce = 'salesforce',
  Shopify = 'shopify',
  ShopifyApp = 'shopifyApp',
  Shopware5 = 'shopware5',
  Shopware6 = 'shopware6',
  Squarespace = 'squarespace',
  Vtex = 'vtex',
  Wordpress = 'wordpress',
}

export enum LengthUnits {
  Cm = 'cm',
  Ft = 'ft',
  In = 'in',
  M = 'm',
  Mm = 'mm',
}

export interface ListSurcharges {
  __typename?: 'ListSurcharges';
  cashOnDeliveries: Maybe<ApplySurcharge>;
  dimensionSurcharges: Maybe<ApplySurcharge>;
  exportDuties: Maybe<ApplySurcharge>;
  fuelSurcharges: Maybe<ApplySurcharge>;
  insurances: Maybe<ApplySurcharge>;
  rateCalculations: Maybe<ApplySurcharge>;
  remoteZones: Maybe<ApplySurcharge>;
  weightSurcharges: Maybe<ApplySurcharge>;
}

export enum Logical {
  And = 'AND',
  Or = 'OR',
}

export interface Mutation {
  __typename?: 'Mutation';
  Brand_createCustom: Brand;
  Brand_updateDefault: Brand;
  Brand_updateSlug: Brand;
  Courier_activation: Scalars['Boolean']['output'];
  Courier_customization: Scalars['Boolean']['output'];
  Courier_deactivation: Scalars['Boolean']['output'];
  Courier_deleteMethod: Scalars['Boolean']['output'];
  Courier_init: Courier;
  Courier_manageWarehouses: Scalars['Boolean']['output'];
  Courier_setCurrency: Scalars['Boolean']['output'];
  Courier_setSurcharges: ListSurcharges;
  Courier_updateCharacteristics: Scalars['Boolean']['output'];
  SetupIntegration_Allegro: Scalars['Boolean']['output'];
  SetupIntegration_Bigcommerce: Scalars['Boolean']['output'];
  SetupIntegration_Etsy: Scalars['Boolean']['output'];
  SetupIntegration_Katana: Scalars['Boolean']['output'];
  SetupIntegration_Magento_1_0: Scalars['Boolean']['output'];
  SetupIntegration_Magento_2_0: Scalars['Boolean']['output'];
  SetupIntegration_Manomano: Scalars['Boolean']['output'];
  SetupIntegration_Odoo: Scalars['Boolean']['output'];
  SetupIntegration_Opencart_3_0: Scalars['Boolean']['output'];
  SetupIntegration_Prestashop: Scalars['Boolean']['output'];
  SetupIntegration_Pulpo: Scalars['Boolean']['output'];
  SetupIntegration_Salesforce: Scalars['Boolean']['output'];
  SetupIntegration_Shopify: Scalars['Boolean']['output'];
  SetupIntegration_Shopware_5_0: Scalars['Boolean']['output'];
  SetupIntegration_Shopware_6_0: Scalars['Boolean']['output'];
  SetupIntegration_Squarespace: Scalars['Boolean']['output'];
  SetupIntegration_Vivino: Scalars['Boolean']['output'];
  SetupIntegration_Voog: Scalars['Boolean']['output'];
  SetupIntegration_Vtex: Scalars['Boolean']['output'];
  SetupIntegration_Wix: Scalars['Boolean']['output'];
  SetupIntegration_Woocommerce: Scalars['Boolean']['output'];
  ShippingRule_changeOrdering: Scalars['Boolean']['output'];
  ShippingRule_createRule: Scalars['Boolean']['output'];
  ShippingRule_deleteRule: Scalars['Boolean']['output'];
  ShippingRule_duplicateRule: Scalars['Boolean']['output'];
  ShippingRule_updateConfig: Scalars['Boolean']['output'];
  Store_addPackaging: Store;
  Store_changePackaging: Store;
  Store_featureRequest: Scalars['Boolean']['output'];
  Store_onboarding: Store;
  Store_printingAndPickingConfig: Store;
  Store_skipOnboardingStep: Store;
  Store_update: Store;
  User_updateProfile: User;
  Warehouse_createWarehouse: Scalars['Boolean']['output'];
  Warehouse_deleteWarehouse: Scalars['Boolean']['output'];
  Warehouse_makeDefault: Scalars['Boolean']['output'];
  Warehouse_updateConfig: Scalars['Boolean']['output'];
}

export type MutationBrand_CreateCustomArgs = {
  input: CreateCustomBrandInput;
};

export type MutationBrand_UpdateDefaultArgs = {
  input: UpdateBrandInput;
};

export type MutationBrand_UpdateSlugArgs = {
  input: UpdateSlugInput;
};

export type MutationCourier_ActivationArgs = {
  countryCode: Scalars['String']['input'];
  courierAlias: Scalars['String']['input'];
};

export type MutationCourier_CustomizationArgs = {
  alias: InputMaybe<Scalars['String']['input']>;
  courierId: Scalars['ID']['input'];
  email: InputMaybe<Scalars['String']['input']>;
};

export type MutationCourier_DeactivationArgs = {
  courierIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationCourier_DeleteMethodArgs = {
  courierId: Scalars['ID']['input'];
  method: Scalars['String']['input'];
};

export type MutationCourier_InitArgs = {
  countryCode: Scalars['String']['input'];
  courierTypeAlias: Scalars['String']['input'];
  credentials: InputMaybe<Scalars['JSON']['input']>;
  thirdPartyCourier: Scalars['Boolean']['input'];
};

export type MutationCourier_ManageWarehousesArgs = {
  courierId: Scalars['ID']['input'];
  warehouses: Array<InputMaybe<Scalars['String']['input']>>;
};

export type MutationCourier_SetCurrencyArgs = {
  courierId: Scalars['ID']['input'];
  currency: Scalars['String']['input'];
};

export type MutationCourier_SetSurchargesArgs = {
  cashOnDeliveries: InputMaybe<ApplySurchargeInput>;
  courier: Scalars['ID']['input'];
  dimensionSurcharges: InputMaybe<ApplySurchargeInput>;
  exportDuties: InputMaybe<ApplySurchargeInput>;
  fuelSurcharges: InputMaybe<ApplySurchargeInput>;
  insurances: InputMaybe<ApplySurchargeInput>;
  rateCalculations: InputMaybe<ApplySurchargeInput>;
  remoteZones: InputMaybe<ApplySurchargeInput>;
  weightSurcharges: InputMaybe<ApplySurchargeInput>;
};

export type MutationCourier_UpdateCharacteristicsArgs = {
  characteristics: Scalars['JSON']['input'];
  courierId: Scalars['ID']['input'];
};

export type MutationSetupIntegration_AllegroArgs = {
  alias: Scalars['String']['input'];
};

export type MutationSetupIntegration_BigcommerceArgs = {
  shopEmail: Scalars['String']['input'];
};

export type MutationSetupIntegration_EtsyArgs = {
  apiKey: Scalars['String']['input'];
  apiSecret: Scalars['String']['input'];
};

export type MutationSetupIntegration_KatanaArgs = {
  alias: Scalars['String']['input'];
};

export type MutationSetupIntegration_Magento_1_0Args = {
  endpoint: Scalars['String']['input'];
};

export type MutationSetupIntegration_Magento_2_0Args = {
  endpoint: Scalars['String']['input'];
};

export type MutationSetupIntegration_ManomanoArgs = {
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationSetupIntegration_OdooArgs = {
  endpoint: Scalars['String']['input'];
};

export type MutationSetupIntegration_PrestashopArgs = {
  endpoint: Scalars['String']['input'];
};

export type MutationSetupIntegration_PulpoArgs = {
  password: Scalars['String']['input'];
  storeUrl: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type MutationSetupIntegration_SalesforceArgs = {
  password: Scalars['String']['input'];
  secretToken: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type MutationSetupIntegration_ShopifyArgs = {
  endpoint: Scalars['String']['input'];
};

export type MutationSetupIntegration_Shopware_5_0Args = {
  apiToken: Scalars['String']['input'];
  endpoint: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type MutationSetupIntegration_Shopware_6_0Args = {
  endpoint: Scalars['String']['input'];
};

export type MutationSetupIntegration_SquarespaceArgs = {
  accessToken: Scalars['String']['input'];
};

export type MutationSetupIntegration_VivinoArgs = {
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  merchantId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type MutationSetupIntegration_VoogArgs = {
  apiToken: Scalars['String']['input'];
  storeUrl: Scalars['String']['input'];
};

export type MutationSetupIntegration_VtexArgs = {
  accountName: Scalars['String']['input'];
  appKey: Scalars['String']['input'];
  appToken: Scalars['String']['input'];
  environment: Scalars['String']['input'];
};

export type MutationSetupIntegration_WixArgs = {
  code: Scalars['String']['input'];
  instanceId: Scalars['String']['input'];
};

export type MutationSetupIntegration_WoocommerceArgs = {
  endpoint: Scalars['String']['input'];
};

export type MutationShippingRule_ChangeOrderingArgs = {
  sortedRules: Array<InputMaybe<Scalars['String']['input']>>;
};

export type MutationShippingRule_CreateRuleArgs = {
  config: ShippingRuleInput;
};

export type MutationShippingRule_DeleteRuleArgs = {
  rule: Scalars['String']['input'];
};

export type MutationShippingRule_DuplicateRuleArgs = {
  rule: Scalars['String']['input'];
};

export type MutationShippingRule_UpdateConfigArgs = {
  config: InputMaybe<ShippingRuleInput>;
  ruleId: Scalars['String']['input'];
};

export type MutationStore_AddPackagingArgs = {
  packaging: PackagingInput;
};

export type MutationStore_ChangePackagingArgs = {
  packId: Scalars['String']['input'];
  packaging: PackagingInput;
};

export type MutationStore_FeatureRequestArgs = {
  feature: StoreFeature;
};

export type MutationStore_OnboardingArgs = {
  input: StoreOnboardingInput;
};

export type MutationStore_PrintingAndPickingConfigArgs = {
  config: StorePrintingAndPicking;
};

export type MutationStore_SkipOnboardingStepArgs = {
  step: OnboardingSteps;
};

export type MutationStore_UpdateArgs = {
  input: StoreUpdateInput;
};

export type MutationUser_UpdateProfileArgs = {
  input: UpdateUserProfileInput;
};

export type MutationWarehouse_CreateWarehouseArgs = {
  settings: WarehouseUpdateInput;
};

export type MutationWarehouse_DeleteWarehouseArgs = {
  warehouseId: Scalars['String']['input'];
};

export type MutationWarehouse_MakeDefaultArgs = {
  warehouseId: Scalars['String']['input'];
};

export type MutationWarehouse_UpdateConfigArgs = {
  settings: WarehouseUpdateInput;
  warehouseId: Scalars['String']['input'];
};

export enum OnboardingSteps {
  BrandAssets = 'brandAssets',
  CmsIntegration = 'cmsIntegration',
  CourierIntegration = 'courierIntegration',
  DeskAgent = 'deskAgent',
  EmailSender = 'emailSender',
  EmailTemplate = 'emailTemplate',
  Fulfillment = 'fulfillment',
  ReturnRules = 'returnRules',
  ShippingRules = 'shippingRules',
}

export interface OrdersInTransitAndIncidents {
  __typename?: 'OrdersInTransitAndIncidents';
  incidents: Maybe<Scalars['Int']['output']>;
  ordersInTransit: Maybe<Scalars['Int']['output']>;
}

export interface Packaging {
  __typename?: 'Packaging';
  barcode: Maybe<Scalars['String']['output']>;
  height: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  isDefault: Maybe<Scalars['Boolean']['output']>;
  isDeleted: Maybe<Scalars['Boolean']['output']>;
  length: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  width: Scalars['Float']['output'];
}

export interface PackagingInput {
  barcode: InputMaybe<Scalars['String']['input']>;
  height: Scalars['Float']['input'];
  isDefault: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted: InputMaybe<Scalars['Boolean']['input']>;
  length: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  width: Scalars['Float']['input'];
}

export interface PickingInput {
  allowManualPicking: InputMaybe<Scalars['Boolean']['input']>;
  allowScanPackaging: InputMaybe<Scalars['Boolean']['input']>;
  groupProductsBy: InputMaybe<GroupProductsBy>;
  matchingScannedNumber: InputMaybe<ScannerMatchOrdering>;
  printAutomatically: InputMaybe<Scalars['Boolean']['input']>;
  processSingleMultiSeparately: InputMaybe<Scalars['Boolean']['input']>;
  showOrderMessage: InputMaybe<Scalars['Boolean']['input']>;
}

export interface PickingSettings {
  __typename?: 'PickingSettings';
  allowManualPicking: Maybe<Scalars['Boolean']['output']>;
  allowScanPackaging: Maybe<Scalars['Boolean']['output']>;
  groupProductsBy: Maybe<GroupProductsBy>;
  matchingScannedNumber: Maybe<ScannerMatchOrdering>;
  printAutomatically: Maybe<Scalars['Boolean']['output']>;
  processSingleMultiSeparately: Maybe<Scalars['Boolean']['output']>;
  showOrderMessage: Maybe<Scalars['Boolean']['output']>;
}

export interface Pickup {
  __typename?: 'Pickup';
  code: Maybe<Scalars['String']['output']>;
  courier: Scalars['String']['output'];
  courier_id: Scalars['String']['output'];
  createdAt: Maybe<Scalars['String']['output']>;
  failed: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isReturn: Scalars['Boolean']['output'];
  rejectDate: Maybe<Scalars['String']['output']>;
  requestedDate: Maybe<Scalars['String']['output']>;
  returnInfo: Maybe<ReturnInfo>;
  shipmentReference: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['String']['output']>;
  warehouse: Maybe<Scalars['String']['output']>;
}

export enum PrinterReference {
  Id = 'ID',
  OriginalId = 'ORIGINAL_ID',
}

export enum PriorityLabelType {
  Pdf = 'pdf',
  Zpl = 'zpl',
}

export interface ProcessedOrdersPerCourier {
  __typename?: 'ProcessedOrdersPerCourier';
  courier: Scalars['String']['output'];
  percentage: Scalars['Int']['output'];
  processedOrders: Scalars['Int']['output'];
}

export interface Query {
  __typename?: 'Query';
  Brand_getById: Brand;
  Brand_getDefault: Brand;
  CourierType_getAll: Array<CourierType>;
  CourierType_loadMethods: Array<CourierMethods>;
  CourierType_loadSchemaByAlias: CourierSchemaDetails;
  CourierType_loadTypesWithStats: Array<CourierTypeWithStats>;
  Courier_loadList: Maybe<Array<Maybe<CourierList>>>;
  Integration_allIntegrationsList: Maybe<Array<Maybe<Integration>>>;
  Integration_availableIntegrationsList: Maybe<Array<Maybe<AvailableIntegration>>>;
  Pickup_info: Maybe<Pickup>;
  ShippingRule_loadRules: Maybe<Array<Maybe<ShippingRule>>>;
  Statistics_countCustomerMailNotifications: CustomerMailNotificationsKpi;
  Statistics_countOrdersInTransitAndIncidents: OrdersInTransitAndIncidents;
  Statistics_countProcessedOrdersPerCourier: Array<Maybe<ProcessedOrdersPerCourier>>;
  Statistics_fulfillment: FulfillmentStatistics;
  Store_current: Maybe<Store>;
  Store_findOneById: Maybe<Store>;
  Store_getPackaging: Maybe<Array<Maybe<Packaging>>>;
  Subscription_current: Maybe<Subscription>;
  User_getProfile: User;
  Warehouse_getInfo: Maybe<Warehouse>;
  Warehouse_loadWarehouses: Maybe<Array<Maybe<Warehouse>>>;
}

export type QueryBrand_GetByIdArgs = {
  brandId: Scalars['String']['input'];
};

export type QueryCourierType_LoadMethodsArgs = {
  courierAlias: Scalars['String']['input'];
};

export type QueryCourierType_LoadSchemaByAliasArgs = {
  alias: Scalars['String']['input'];
};

export type QueryCourierType_LoadTypesWithStatsArgs = {
  countryCode: Scalars['String']['input'];
  defaults: Scalars['Boolean']['input'];
};

export type QueryCourier_LoadListArgs = {
  countryCode: Scalars['String']['input'];
  defaults: Scalars['Boolean']['input'];
};

export type QueryPickup_InfoArgs = {
  otn: InputMaybe<Scalars['String']['input']>;
};

export type QueryStatistics_CountCustomerMailNotificationsArgs = {
  input: InputMaybe<CustomerMailNotificationsKpiInput>;
};

export type QueryStatistics_CountOrdersInTransitAndIncidentsArgs = {
  cache: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryStatistics_CountProcessedOrdersPerCourierArgs = {
  cache: InputMaybe<Scalars['Boolean']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
};

export type QueryStatistics_FulfillmentArgs = {
  cache: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryStore_FindOneByIdArgs = {
  id: Scalars['String']['input'];
};

export type QueryWarehouse_GetInfoArgs = {
  id: Scalars['String']['input'];
};

export interface ReturnInfo {
  __typename?: 'ReturnInfo';
  address: Maybe<Scalars['String']['output']>;
  city: Maybe<Scalars['String']['output']>;
  contactName: Maybe<Scalars['String']['output']>;
  contactPhone: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  countryCode: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  postcode: Maybe<Scalars['String']['output']>;
  state: Maybe<Scalars['String']['output']>;
}

export interface Rule {
  __typename?: 'Rule';
  condition: Scalars['String']['output'];
  key: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  value: Scalars['JSON']['output'];
}

export interface RuleInput {
  condition: Scalars['String']['input'];
  key: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  value: Scalars['JSON']['input'];
}

export enum ScannerMatchOrdering {
  Barcode = 'barcode',
  BarcodeOrSku = 'barcodeOrSku',
  Sku = 'sku',
  SkuOrBarcode = 'skuOrBarcode',
}

export interface ShippingRule {
  __typename?: 'ShippingRule';
  actions: Array<Maybe<Action>>;
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  ordering: Scalars['Int']['output'];
  sectionOperator: Logical;
  sections: Array<Maybe<Condition>>;
  store: Scalars['String']['output'];
}

export interface ShippingRuleInput {
  actions: Array<InputMaybe<ActionInput>>;
  active: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  ordering: InputMaybe<Scalars['Int']['input']>;
  sectionOperator: Logical;
  sections: Array<InputMaybe<ConditionInput>>;
  store: Scalars['String']['input'];
}

export enum StockOptions {
  ShipAndRefund = 'SHIP_AND_REFUND',
  ShipAvailableAndWait = 'SHIP_AVAILABLE_AND_WAIT',
  WaitAllThenShip = 'WAIT_ALL_THEN_SHIP',
}

export interface Store {
  __typename?: 'Store';
  apiKey: Scalars['String']['output'];
  approved: Scalars['Boolean']['output'];
  clientCode: Scalars['String']['output'];
  company: Maybe<StoreCompany>;
  currency: StoreCurrency;
  deskEnabled: Scalars['Boolean']['output'];
  documentPrintingType: Scalars['String']['output'];
  groupProductsInShippingQueue: Maybe<Scalars['Boolean']['output']>;
  hasPendingOnboarding: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  invoiceCheckingEnabled: Scalars['Boolean']['output'];
  is3pl: Scalars['Boolean']['output'];
  isActive: Scalars['Boolean']['output'];
  isScanAndPackEnabled: Scalars['Boolean']['output'];
  lengthUnit: LengthUnits;
  onboardingSteps: Array<Maybe<OnboardingStep>>;
  optimizeBulkPrinting: Maybe<Scalars['Boolean']['output']>;
  outOfStockRule: StockOptions;
  pickingWithBaskets: Maybe<BasketConfig>;
  printProductionList: Maybe<Scalars['Boolean']['output']>;
  printerType: Scalars['String']['output'];
  printingReferenceType: Maybe<PrinterReference>;
  priorityLabelType: Scalars['String']['output'];
  promoCode: Maybe<Scalars['String']['output']>;
  scanAndPack: Maybe<PickingSettings>;
  skipPickingQueue: Maybe<Scalars['Boolean']['output']>;
  statistics: Maybe<StoreStatistics>;
  useDirectPrintingApp: Maybe<Scalars['Boolean']['output']>;
  validation: Maybe<ValidationFields>;
  weightUnit: WeightUnits;
}

export interface StoreCompany {
  __typename?: 'StoreCompany';
  address: Maybe<Scalars['String']['output']>;
  city: Maybe<Scalars['String']['output']>;
  contactEmail: Maybe<Scalars['String']['output']>;
  contactPhone: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  countryCode: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  postcode: Maybe<Scalars['String']['output']>;
  registrationNumber: Maybe<Scalars['String']['output']>;
  supportEmail: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
  vat: Maybe<Scalars['String']['output']>;
}

export interface StoreCompanyUpdateInput {
  address: InputMaybe<Scalars['String']['input']>;
  city: InputMaybe<Scalars['String']['input']>;
  contactEmail: InputMaybe<Scalars['String']['input']>;
  contactPhone: InputMaybe<Scalars['String']['input']>;
  country: InputMaybe<Scalars['String']['input']>;
  countryCode: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  postcode: InputMaybe<Scalars['String']['input']>;
  registrationNumber: InputMaybe<Scalars['String']['input']>;
  supportEmail: InputMaybe<Scalars['String']['input']>;
  url: InputMaybe<Scalars['String']['input']>;
  vat: InputMaybe<Scalars['String']['input']>;
}

export enum StoreCurrency {
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD',
}

export enum StoreFeature {
  OutvioDesk = 'OutvioDesk',
  VoiceCall = 'VoiceCall',
}

export interface StoreOnboardingInput {
  address: InputMaybe<Scalars['String']['input']>;
  city: InputMaybe<Scalars['String']['input']>;
  contactEmail: InputMaybe<Scalars['String']['input']>;
  contactPhone: InputMaybe<Scalars['String']['input']>;
  countryCode: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  postcode: InputMaybe<Scalars['String']['input']>;
  promoCode: InputMaybe<Scalars['String']['input']>;
}

export interface StorePrintingAndPicking {
  documentPrintingType: Scalars['String']['input'];
  groupProductsInShippingQueue: InputMaybe<Scalars['Boolean']['input']>;
  isScanAndPackEnabled: InputMaybe<Scalars['Boolean']['input']>;
  optimizeBulkPrinting: InputMaybe<Scalars['Boolean']['input']>;
  outOfStockRule: InputMaybe<StockOptions>;
  pickingWithBaskets: InputMaybe<BasketInput>;
  printProductionList: InputMaybe<Scalars['Boolean']['input']>;
  printerType: Scalars['String']['input'];
  printingReferenceType: InputMaybe<PrinterReference>;
  priorityLabelType: Scalars['String']['input'];
  scanAndPack: InputMaybe<PickingInput>;
  skipPickingQueue: InputMaybe<Scalars['Boolean']['input']>;
  useDirectPrintingApp: InputMaybe<Scalars['Boolean']['input']>;
  validation: InputMaybe<ValidationInput>;
}

export interface StoreStatistics {
  __typename?: 'StoreStatistics';
  clientSource: Maybe<Scalars['String']['output']>;
  partnerName: Maybe<Scalars['String']['output']>;
  shipmentsPerMonth: Maybe<Scalars['String']['output']>;
  whatCanWeDo: Maybe<Array<Maybe<Scalars['String']['output']>>>;
}

export interface StoreStatisticsInput {
  clientSource: InputMaybe<Scalars['String']['input']>;
  partnerName: InputMaybe<Scalars['String']['input']>;
  shipmentsPerMonth: InputMaybe<Scalars['String']['input']>;
  whatCanWeDo: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface StoreUpdateInput {
  company: InputMaybe<StoreCompanyUpdateInput>;
  enableTrackingMode: InputMaybe<Scalars['Boolean']['input']>;
  statistics: InputMaybe<StoreStatisticsInput>;
}

export interface Subscription {
  __typename?: 'Subscription';
  features: Maybe<Features>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isConquer: Scalars['Boolean']['output'];
  isTrial: Scalars['Boolean']['output'];
  plan: Maybe<SubscriptionPlan>;
}

export enum SubscriptionPlan {
  Connect3pl = 'connect3pl',
  Conquer = 'conquer',
  Grow = 'grow',
  Launch = 'launch',
  Partner = 'partner',
  Starter = 'starter',
}

export interface Translation {
  __typename?: 'Translation';
  DE: Maybe<Scalars['String']['output']>;
  EN: Scalars['String']['output'];
  ES: Maybe<Scalars['String']['output']>;
  PL: Maybe<Scalars['String']['output']>;
  PT: Maybe<Scalars['String']['output']>;
}

export interface UpdateBrandInput {
  brandUrl: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateSlugInput {
  id: Scalars['ID']['input'];
  slug: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateUserProfileInput {
  firstname: InputMaybe<Scalars['String']['input']>;
  lastname: InputMaybe<Scalars['String']['input']>;
}

export interface User {
  __typename?: 'User';
  email: Scalars['String']['output'];
  emailOnlySignature: Maybe<Scalars['Boolean']['output']>;
  firstname: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  language: Maybe<Scalars['String']['output']>;
  lastname: Maybe<Scalars['String']['output']>;
  position: Maybe<Scalars['String']['output']>;
  roles: Array<UserRole>;
  signature: Maybe<Scalars['String']['output']>;
  signatures: Maybe<Array<Maybe<UserSignature>>>;
  status: Maybe<UserStatus>;
  timeZone: Maybe<Scalars['String']['output']>;
  viewAllDeskFolder: Maybe<Scalars['Boolean']['output']>;
}

export enum UserRole {
  Admin = 'admin',
  Administrator = 'administrator',
  Customer = 'customer',
  CustomerSupport = 'customer_support',
  Manager = 'manager',
  Owner = 'owner',
  User = 'user',
  Warehouse = 'warehouse',
}

export interface UserSignature {
  __typename?: 'UserSignature';
  language: Maybe<Scalars['String']['output']>;
  signature: Maybe<Scalars['String']['output']>;
}

export enum UserStatus {
  Away = 'away',
  Offline = 'offline',
  Online = 'online',
  Reassign = 'reassign',
}

export interface ValidationFields {
  __typename?: 'ValidationFields';
  cardId: Maybe<Scalars['Boolean']['output']>;
}

export interface Warehouse {
  __typename?: 'Warehouse';
  activationRequested: Scalars['Boolean']['output'];
  address: Address;
  autoGenerateShippingList: Maybe<Scalars['Boolean']['output']>;
  hideFromAddressInShippingLabel: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isBlocked: Scalars['Boolean']['output'];
  isDefault: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  labelAddress: Maybe<Address>;
  name: Scalars['String']['output'];
  returnerCode: Maybe<Scalars['String']['output']>;
  senderCode: Maybe<Scalars['String']['output']>;
  shippingListCutOffTime: Maybe<Scalars['String']['output']>;
  store: Scalars['String']['output'];
  timeZone: Maybe<Scalars['String']['output']>;
}

export interface WarehouseUpdateInput {
  address: AddressInput;
  autoGenerateShippingList: InputMaybe<Scalars['Boolean']['input']>;
  hideFromAddressInShippingLabel: InputMaybe<Scalars['Boolean']['input']>;
  isDefault: InputMaybe<Scalars['Boolean']['input']>;
  labelAddress: InputMaybe<AddressInput>;
  returnerCode: InputMaybe<Scalars['String']['input']>;
  senderCode: InputMaybe<Scalars['String']['input']>;
  shippingListCutOffTime: InputMaybe<Scalars['String']['input']>;
  timeZone: InputMaybe<Scalars['String']['input']>;
}

export enum WeightUnits {
  G = 'g',
  Kg = 'kg',
  Lb = 'lb',
  Oz = 'oz',
}

export enum WhatCanWeDo {
  Fulfillment = 'fulfillment',
  Notifications = 'notifications',
  OutvioDesk = 'outvioDesk',
  ReturnsAndExchanges = 'returnsAndExchanges',
  Shipping = 'shipping',
  Tracking = 'tracking',
}

export interface OnboardingStep {
  __typename?: 'onboardingStep';
  completed: Scalars['Boolean']['output'];
  step: OnboardingSteps;
}

export interface ValidationInput {
  cardId: InputMaybe<Scalars['Boolean']['input']>;
}
