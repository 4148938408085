import {
  BoldTextShortcut,
  BulletedListIcon,
  ItalicTextShortcut,
  NumberedListIcon,
  ThreeDotsIconShortcut,
  UnderlineTextShortcut,
} from 'icons';
import { useIntl } from 'localization';
import { match } from 'ts-pattern';

import { ShortCuts } from '../RichTextEditor';
import { AdditionalButton } from './AdditionalButton';
import { BlockButton } from './BlockButton';
import { InsertImageButton } from './InsertImageButton';
import { InsertLinkButton } from './InsertLinkButton';
import { MarkButton } from './MarkButton';
import MarkButtonEmojiPicker from './MarkButtonEmojiPicker';

const ShortcutButton = ({
  shortcut,
  title,
  withTitle,
  onClose,
}: {
  shortcut: ShortCuts;
  title: string;
  withTitle?: boolean;
  onClose?: () => void;
}) => {
  return match(shortcut)
    .with('bold', () => (
      <MarkButton
        format="bold"
        title={title}
        withTitle={withTitle}
        icon={<BoldTextShortcut className="w-7 h-7" />}
      />
    ))
    .with('italic', () => (
      <MarkButton
        format="italic"
        title={title}
        withTitle={withTitle}
        icon={<ItalicTextShortcut className="w-7 h-7" />}
      />
    ))
    .with('underline', () => (
      <MarkButton
        format="underline"
        title={title}
        withTitle={withTitle}
        icon={<UnderlineTextShortcut className="w-7 h-7" />}
      />
    ))
    .with('link', () => <InsertLinkButton title={title} withTitle={withTitle} onClose={onClose} />)
    .with('emoji', () => (
      <MarkButtonEmojiPicker title={title} withTitle={withTitle} onClose={onClose} />
    ))
    .with('image', () => (
      <InsertImageButton title={title} withTitle={withTitle} onClose={onClose} />
    ))
    .with('numbered-list', () => (
      <BlockButton
        format="numbered-list"
        title={title}
        withTitle={withTitle}
        icon={<NumberedListIcon className="w-7 h-7" />}
      />
    ))
    .with('bulleted-list', () => (
      <BlockButton
        format="bulleted-list"
        title={title}
        withTitle={withTitle}
        icon={<BulletedListIcon className="w-7 h-7" />}
      />
    ))
    .exhaustive();
};

const ToolbarButtons = ({
  shortCuts,
  withTitle,
  onClose,
}: {
  shortCuts?: ShortCuts[];
  withTitle?: boolean;
  onClose?: () => void;
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {shortCuts?.map((x, index) => (
        <ShortcutButton
          shortcut={x}
          title={formatMessage({ id: `desk-app.rich-editor.${x}` })}
          withTitle={withTitle}
          key={`toolbar-button-shortcut-${x}-${index}`}
          onClose={onClose}
        />
      ))}
    </>
  );
};

type ToolBarProps = {
  additionalToolbarButtons?: JSX.Element;
  visibleToolbarShortcuts?: ShortCuts[];
  hidddenToolbarShortcuts?: ShortCuts[];
  actionElement?: JSX.Element;
};

const ToolBar = ({
  additionalToolbarButtons,
  visibleToolbarShortcuts,
  hidddenToolbarShortcuts,
  actionElement,
}: ToolBarProps) => {
  return (
    <div className="mt-4 flex gap-2 justify-between">
      <div className="flex gap-1">
        <ToolbarButtons shortCuts={visibleToolbarShortcuts} />
        {additionalToolbarButtons}

        {!!hidddenToolbarShortcuts?.length && (
          <AdditionalButton
            icon={<ThreeDotsIconShortcut className="w-7 h-7" />}
            shorcuts={(onClose) => (
              <ToolbarButtons shortCuts={hidddenToolbarShortcuts} onClose={onClose} withTitle />
            )}
          />
        )}
      </div>
      {actionElement}
    </div>
  );
};

export default ToolBar;
