import { RenderElementProps } from 'slate-react';

import ImageComponent from './elements/ImageComponent';
import LinkComponent from './elements/LinkComponent';

const RenderElement = (props: RenderElementProps) => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case 'link':
      return <LinkComponent {...props} />;
    case 'image':
      return <ImageComponent {...props} />;
    case 'bulleted-list':
      return (
        <ul className="list-disc list-inside" {...attributes}>
          {children}
        </ul>
      );
    case 'numbered-list':
      return (
        <ol className="list-decimal list-inside" {...attributes}>
          {children}
        </ol>
      );
    case 'list-item':
      return (
        <li className="font-normal" {...attributes}>
          {children}
        </li>
      );

    default:
      return <p {...attributes}>{children}</p>;
  }
};

export default RenderElement;
