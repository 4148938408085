export function BulletedListIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g id="bullet" transform="translate(-2.339 -2.375)">
        <g
          id="Rectangle_8501"
          data-name="Rectangle 8501"
          transform="translate(2.339 2.375)"
          stroke="currentColor"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <path
          id="format_list_bulleted_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24"
          d="M146.172-772.9a.681.681,0,0,1-.5-.2.689.689,0,0,1-.2-.506.688.688,0,0,1,.2-.505.682.682,0,0,1,.5-.2h9.122a.681.681,0,0,1,.5.2.689.689,0,0,1,.2.506.687.687,0,0,1-.2.505.681.681,0,0,1-.5.2Zm0-5.676a.681.681,0,0,1-.5-.2.689.689,0,0,1-.2-.506.688.688,0,0,1,.2-.505.682.682,0,0,1,.5-.2h9.122a.68.68,0,0,1,.5.2.689.689,0,0,1,.2.506.687.687,0,0,1-.2.505.681.681,0,0,1-.5.2Zm0-5.676a.681.681,0,0,1-.5-.2.689.689,0,0,1-.2-.506.688.688,0,0,1,.2-.505.682.682,0,0,1,.5-.2h9.122a.681.681,0,0,1,.5.2.689.689,0,0,1,.2.506.687.687,0,0,1-.2.505.681.681,0,0,1-.5.2Zm-4.606,12.217a1.5,1.5,0,0,1-1.106-.462,1.519,1.519,0,0,1-.46-1.111,1.519,1.519,0,0,1,.46-1.111,1.5,1.5,0,0,1,1.106-.462,1.5,1.5,0,0,1,1.106.462,1.519,1.519,0,0,1,.46,1.111,1.519,1.519,0,0,1-.46,1.111A1.5,1.5,0,0,1,141.566-772.04Zm0-5.676a1.5,1.5,0,0,1-1.106-.462,1.519,1.519,0,0,1-.46-1.111,1.519,1.519,0,0,1,.46-1.111,1.5,1.5,0,0,1,1.106-.462,1.5,1.5,0,0,1,1.106.462,1.519,1.519,0,0,1,.46,1.111,1.519,1.519,0,0,1-.46,1.111A1.5,1.5,0,0,1,141.566-777.716Zm0-5.676a1.5,1.5,0,0,1-1.106-.462,1.519,1.519,0,0,1-.46-1.111,1.519,1.519,0,0,1,.46-1.111,1.5,1.5,0,0,1,1.106-.462,1.5,1.5,0,0,1,1.106.462,1.519,1.519,0,0,1,.46,1.111,1.519,1.519,0,0,1-.46,1.111A1.5,1.5,0,0,1,141.566-783.392Z"
          transform="translate(-133.66 793.665)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
