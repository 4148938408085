import { Middleware, flip, offset, shift } from '@floating-ui/react-dom';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { twMerge } from 'tailwind-merge';

import { Tooltip } from '../../molecules';
import { CustomTooltipProps } from '../../molecules/Tooltip/Tooltip';

type HelpIconWithTooltipProps = {
  title?: string;
  tooltipPlacement?: CustomTooltipProps['placement'];
  tooltipClassName?: string;
  middleware?: Middleware[];
};

export const HelpIconWithTooltip = ({
  title,
  tooltipPlacement,
  tooltipClassName,
  middleware,
}: HelpIconWithTooltipProps) => {
  return (
    <div className="group flex items-start">
      <Tooltip
        title={title}
        placement={tooltipPlacement}
        className={twMerge('text-center group', tooltipClassName)}
        middleware={middleware ? middleware : [offset(16), flip(), shift()]}
      >
        <div className="group-hover:hidden">
          <HelpOutlineRoundedIcon
            sx={{ fontSize: 20 }}
            color="inherit"
            className="text-v2blueGray-500"
          />
        </div>
        <div className="hidden group-hover:block">
          <HelpRoundedIcon sx={{ fontSize: 20 }} color="inherit" className="text-v2blueGray-500" />
        </div>
      </Tooltip>
    </div>
  );
};
