import { gql } from '@apollo/client';

export const UserProfileUpdateMutation = gql`
  mutation User_updateProfile($input: UpdateUserProfileInput!) {
    User_updateProfile(input: $input) {
      id
      firstname
      lastname
      emailOnlySignature
      signatures {
        language
        signature
      }
    }
  }
`;

export const WelcomeInfoUpdateMutation = gql`
  mutation Store_onboarding(
    $storeOnboarding: StoreOnboardingInput!
    $user: UpdateUserProfileInput!
    $brand: UpdateBrandInput!
  ) {
    Store_onboarding(input: $storeOnboarding) {
      company {
        address
        city
        contactEmail
        contactPhone
        countryCode
        name
        postcode
      }
      promoCode
      id
    }
    User_updateProfile(input: $user) {
      firstname
      lastname
      id
    }
    Brand_updateDefault(input: $brand) {
      brandUrl
      id
    }
  }
`;

export const WelcomeStatisticsUpdateMutation = gql`
  mutation Store_update($input: StoreUpdateInput!) {
    Store_update(input: $input) {
      statistics {
        shipmentsPerMonth
        whatCanWeDo
        clientSource
        partnerName
      }
      hasPendingOnboarding
      id
      onboardingSteps {
        step
        completed
      }
    }
  }
`;

export const CompanyInfoUpdateMutation = gql`
  mutation Store_update($input: StoreUpdateInput!) {
    Store_update(input: $input) {
      company {
        name
        url
        address
        postcode
        city
        country
        countryCode
        vat
        registrationNumber
        contactEmail
        contactPhone
        supportEmail
      }
      id
    }
  }
`;

export const TrackingModeMutation = gql`
  mutation Store_update($input: StoreUpdateInput!) {
    Store_update(input: $input) {
      id
      onboardingSteps {
        step
        completed
      }
    }
  }
`;

export const SkipOnboardingStepMutation = gql`
  mutation Store_update($step: OnboardingSteps!) {
    Store_skipOnboardingStep(step: $step) {
      id
      onboardingSteps {
        step
        completed
      }
    }
  }
`;

export const FeatureRequestMutation = gql`
  mutation Store_featureRequest($feature: StoreFeature!) {
    Store_featureRequest(feature: $feature)
  }
`;

export const BrandUpdateSlugMutation = gql`
  mutation Brand_updateSlug($input: UpdateSlugInput!) {
    Brand_updateSlug(input: $input) {
      id
      slug
      trackingSlug
      logo
      brandUrl
      type
    }
  }
`;

export const BrandCreateCustomMutation = gql`
  mutation Brand_createCustom($input: CreateCustomBrandInput!) {
    Brand_createCustom(input: $input) {
      id
      slug
      trackingSlug
      logo
      brandUrl
      type
    }
  }
`;

export const CreateWarehouseMutation = gql`
  mutation Warehouse_createWarehouse($settings: WarehouseUpdateInput!) {
    Warehouse_createWarehouse(settings: $settings)
  }
`;

export const UpdateWarehouseMutation = gql`
  mutation Warehouse_updateConfig($settings: WarehouseUpdateInput!, $warehouseId: String!) {
    Warehouse_updateConfig(settings: $settings, warehouseId: $warehouseId)
  }
`;

export const DeleteWarehouseMutation = gql`
  mutation Warehouse_deleteWarehouse($warehouseId: String!) {
    Warehouse_deleteWarehouse(warehouseId: $warehouseId)
  }
`;

export const MakeDefaultWarehouseMutation = gql`
  mutation Warehouse_makeDefault($warehouseId: String!) {
    Warehouse_makeDefault(warehouseId: $warehouseId)
  }
`;

export const CreatePackageMutation = gql`
  mutation Store_addPackaging($packaging: PackagingInput!) {
    Store_addPackaging(packaging: $packaging) {
      id
    }
  }
`;

export const UpdatePackageMutation = gql`
  mutation Store_changePackaging($packaging: PackagingInput!, $packId: String!) {
    Store_changePackaging(packaging: $packaging, packId: $packId) {
      id
    }
  }
`;

export const UpdatePrintingMutation = gql`
  mutation Store_printConfig($printing: StorePrintingSettings!) {
    Store_printConfig(printing: $printing) {
      documentPrintingType
      printerType
      optimizeBulkPrinting
      id
      priorityLabelType
      useDirectPrintingApp
    }
  }
`;

export const UpdateShippingRulesSortingIndexMutation = gql`
  mutation ShippingRule_changeOrdering($sortedRules: [String]!) {
    ShippingRule_changeOrdering(sortedRules: $sortedRules)
  }
`;

export const UpdateShippingRuleMutation = gql`
  mutation ShippingRule_updateConfig($ruleId: String!, $config: ShippingRuleInput) {
    ShippingRule_updateConfig(ruleId: $ruleId, config: $config)
  }
`;

export const UpdateFulfillmentSettingsMutation = gql`
  mutation Store_printingAndPickingConfig($config: StorePrintingAndPicking!) {
    Store_printingAndPickingConfig(config: $config) {
      id
      isScanAndPackEnabled
      outOfStockRule
      pickingWithBaskets {
        enabled
        maxBasketsPerPickingList
        maxProductsPerBasket
      }
      printProductionList
      printingReferenceType
      groupProductsInShippingQueue
      validation {
        cardId
      }
      skipPickingQueue
      scanAndPack {
        allowManualPicking
        allowScanPackaging
        groupProductsBy
        matchingScannedNumber
        printAutomatically
        processSingleMultiSeparately
        showOrderMessage
      }
    }
  }
`;

export const DuplicateShippingRuleMutation = gql`
  mutation ShippingRule_duplicateRule($rule: String!) {
    ShippingRule_duplicateRule(rule: $rule)
  }
`;

export const DeleteShippingRuleMutation = gql`
  mutation ShippingRule_deleteRule($rule: String!) {
    ShippingRule_deleteRule(rule: $rule)
  }
`;

export const CreateShippingRuleMutation = gql`
  mutation ShippingRule_createRule($config: ShippingRuleInput!) {
    ShippingRule_createRule(config: $config)
  }
`;

export const UpdateCourierCharacteristicsMutation = gql`
  mutation Courier_updateCharacteristics($courierId: ID!, $characteristics: JSON!) {
    Courier_updateCharacteristics(courierId: $courierId, characteristics: $characteristics)
  }
`;

export const UpdateCourierCustomizationMutation = gql`
  mutation Courier_customization($courierId: ID!, $alias: String, $email: String) {
    Courier_customization(courierId: $courierId, alias: $alias, email: $email)
  }
`;

export const ManageWarehousesMutation = gql`
  mutation Courier_manageWarehouses($courierId: ID!, $warehouses: [String]!) {
    Courier_manageWarehouses(courierId: $courierId, warehouses: $warehouses)
  }
`;

export const UpdateCourierSurchargesMutation = gql`
  mutation Courier_setSurcharges(
    $courier: ID!
    $rateCalculations: ApplySurchargeInput
    $cashOnDeliveries: ApplySurchargeInput
    $insurances: ApplySurchargeInput
    $remoteZones: ApplySurchargeInput
    $weightSurcharges: ApplySurchargeInput
    $dimensionSurcharges: ApplySurchargeInput
    $exportDuties: ApplySurchargeInput
    $fuelSurcharges: ApplySurchargeInput
  ) {
    Courier_setSurcharges(
      courier: $courier
      rateCalculations: $rateCalculations
      cashOnDeliveries: $cashOnDeliveries
      insurances: $insurances
      remoteZones: $remoteZones
      weightSurcharges: $weightSurcharges
      dimensionSurcharges: $dimensionSurcharges
      exportDuties: $exportDuties
      fuelSurcharges: $fuelSurcharges
    ) {
      rateCalculations {
        applyOnAllShippingMethods
        surcharges
      }
      cashOnDeliveries {
        applyOnAllShippingMethods
        surcharges
      }
      insurances {
        applyOnAllShippingMethods
        surcharges
      }
      fuelSurcharges {
        applyOnAllShippingMethods
        surcharges
      }
      exportDuties {
        applyOnAllShippingMethods
        surcharges
      }
      dimensionSurcharges {
        applyOnAllShippingMethods
        surcharges
      }
      remoteZones {
        applyOnAllShippingMethods
        surcharges
      }
      weightSurcharges {
        applyOnAllShippingMethods
        surcharges
      }
    }
  }
`;

export const UpdateCourierCurrencyMutation = gql`
  mutation Courier_setCurrency($courierId: ID!, $currency: String!) {
    Courier_setCurrency(courierId: $courierId, currency: $currency)
  }
`;
