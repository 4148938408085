import { CourierInfo, CourierSchemaType } from 'app-types';
import { IResponseCourierIcons } from 'app-types/src/order';
import { request as requestAlt } from 'ui';

import request from '../../request';

export type UpdateCouriersSupportEmailsData = {
  data: Array<{
    email: string;
    courierId: string;
  }>;
};

export interface IcheckCredentialsReply {
  success: boolean;
  idIntegration: string;
  data?: any;
}

export interface ICourierOauthSignUpResponse {
  redirectUri: string;
  success: boolean;
}

type UpdateCouriersSupportEmailsResponse = {
  data: Array<{ courierId: string; email: string; isValidEmail: boolean }>;
};

export const updateCouriersSupportEmails = (data: UpdateCouriersSupportEmailsData) =>
  request<UpdateCouriersSupportEmailsResponse>(
    `${process.env.OUTVIO_API_URL}/courier/update-courier-support-mail`,
    {
      method: 'POST',
      data,
      secure: true,
    },
  ).then((response) => response?.data);

export const getCourierIcons = () =>
  request<IResponseCourierIcons>(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/courier/schema`,
    {
      method: 'GET',
      secure: true,
    },
  ).then((res) => res?.data);

export const manageWarehouse = (idCourier: string, warehouses: string[]) => {
  return request(`${process.env.OUTVIO_API_URL}/courier/manage-warehouses`, {
    method: 'POST',
    secure: true,
    data: {
      courierId: idCourier,
      warehouses,
    },
  });
};

export const deleteContractCustomCourier = (idCourier: string): Promise<any> => {
  return request(`${process.env.OUTVIO_API_URL}/courier/store/${idCourier}`, {
    method: 'DELETE',
    secure: true,
  });
};

export const initCourier = (data: any): Promise<IcheckCredentialsReply> => {
  return requestAlt(`${process.env.OUTVIO_API_URL}/courier/init`, {
    method: 'POST',
    data,
    secure: true,
  });
};

export const checkCredentials = (data: any): Promise<IcheckCredentialsReply> => {
  return requestAlt(`${process.env.OUTVIO_API_URL}/courier/check-credentials/${data.courierId}`, {
    method: 'POST',
    data: {
      credentials: { ...data.credentials },
      thirdPartyCourier: data?.thirdPartyCourier || false,
    },
    secure: true,
  });
};

export const activateCustomCourier = (data: {
  courierAlias: string;
  countryCode: string;
}): Promise<unknown> =>
  request(`${process.env.OUTVIO_API_URL}/courier/activate-custom-courier`, {
    method: 'POST',
    secure: true,
    data,
  });

export const deactivateCourier = (data: { courierIds: string[] }): Promise<unknown> =>
  request(`${process.env.OUTVIO_API_URL}/courier/deactivate-courier`, {
    method: 'POST',
    secure: true,
    data,
  });

export const getCourierOAuthSignupLink = (
  courierTypeAlias: string,
  countryCode: string,
  courierId?: string,
): Promise<ICourierOauthSignUpResponse> => {
  const initial = { courierTypeAlias, countryCode };
  const data = courierId ? { ...initial, courierId } : initial;

  return requestAlt(`${process.env.OUTVIO_API_URL}/oauth/build-oauth-url`, {
    method: 'POST',
    secure: true,
    data,
  });
};

export const getCustomCourierSchema = (courierId?: string): Promise<CourierSchemaType> =>
  requestAlt(`${process.env.OUTVIO_API_URL}/courier/schema/${courierId || ''}`, {
    method: 'GET',
    secure: true,
  }).then((res) => res.data);

export const getCustomCourierInfo = (
  courierId: string,
): Promise<{
  data: CourierInfo;
}> =>
  requestAlt(`${process.env.OUTVIO_API_URL}/courier/custom/${courierId}`, {
    method: 'GET',
    secure: true,
  });

export const getCourierSurcharges = (idCourier: string): Promise<any> => {
  return requestAlt(`${process.env.OUTVIO_API_URL}/courier-surcharges/${idCourier}`, {
    method: 'GET',
    secure: true,
  }).then((res) => res.courierSurcharges);
};
