import { Editor, Node, NodeEntry, Range, Element as SlateElement, Text, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { NodeInsertNodesOptions } from 'slate/dist/interfaces/transforms/node';

import { emojiMap } from './constants';
import { CustomElement, ElementType, Format } from './types';

export const LIST_TYPES = ['numbered-list', 'bulleted-list'] as const;
export type ListType = (typeof LIST_TYPES)[number];

const isElementActive = (editor: Editor, type: ElementType) => {
  const [result] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === type,
  });
  return !!result;
};

const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
};

const isFormatActive = (editor: Editor, format: Format) => {
  const { selection } = editor;

  if (!selection) return false;

  // If the selection is collapsed, check the marks at the cursor position
  if (Range.isCollapsed(selection)) {
    try {
      const marks = Editor.marks(editor);
      return marks ? marks[format] === true : false;
    } catch (e) {
      return false; // Safe fallback when selection is not on a leaf node
    }
  }

  // Look for text nodes with the format applied within the selection range
  const [match] = Editor.nodes(editor, {
    match: (n) => Text.isText(n) && n[format] === true,
    mode: 'all',
  });

  return !!match;
};

const getRawText = (editor: Editor) => {
  return editor.children.map((node) => Node.string(node)).join('\n');
};

const toggleFormat = (editor: Editor, format: Format) => {
  const isActive = isFormatActive(editor, format);

  if (editor.selection && Range.isCollapsed(editor.selection)) {
    // Apply or remove the mark from the cursor position when no text is selected
    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  } else {
    // Apply or remove the mark to the selected text
    Transforms.setNodes(
      editor,
      { [format]: isActive ? null : true },
      {
        match: (n) => Text.isText(n), // Apply to text nodes only
        split: true,
      },
    );
  }

  ReactEditor.focus(editor);
};

const wrapLink = (editor: Editor, url: string, textLink: string) => {
  if (isElementActive(editor, 'link')) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: CustomElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: textLink }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const insertLink = (editor: Editor, url: string, textLink: string) => {
  if (editor.selection) {
    wrapLink(editor, url, textLink);
    Transforms.move(editor, { distance: textLink.length, unit: 'offset' });
    ReactEditor.focus(editor);
  }
};

const getSelectedLink = (editor: Editor) => {
  const [linkEntry] = Editor.nodes(editor, {
    match: (n) => SlateElement.isElement(n) && n.type === 'link',
  });
  return linkEntry;
};

function isUrl(text: string) {
  return text.match(/^https?:\/\/[^\s]+$/);
}

const isRichText = (template: string | CustomElement[]): boolean => {
  return Array.isArray(template) && template.every((node) => typeof node === 'object');
};

const insertTemplate = (editor: Editor, template: string | CustomElement[]) => {
  // const { selection } = editor;

  // Move selection to the end if nothing is selected
  // if (!selection || Range.isCollapsed(selection)) {
  // Transforms.select(editor, Editor.end(editor, []));
  // }

  if (typeof template !== 'string') {
    const sanitizedNodes = (template as CustomElement[]).map((node) => {
      return {
        ...node,
        children: node.children.map((child) => ({
          ...child,
          key: undefined, // Slate will generate a new key for each child
        })),
      };
    });

    const options: NodeInsertNodesOptions<Node> = {};
    // if (editor?.selection) {
    //   const [currentNode, path] = Editor.node(editor, editor.selection);
    //   if (Node.string(currentNode) === '') {
    //     options.at = path;
    //   }
    // }

    // If it's rich text, insert nodes
    Transforms.insertNodes(editor, sanitizedNodes, options);
    Transforms.collapse(editor, { edge: 'end' });
  } else {
    // Insert plain text
    Transforms.insertText(editor, template);
  }

  // Move the cursor to the end of the inserted content
  ReactEditor.focus(editor);
};

const isImageUrl = (url?: string) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split('.').pop();

  return ['jpg', 'png', 'webp'].includes(ext || '');
};

const insertImage = (editor: Editor, url: string) => {
  const image: CustomElement = { type: 'image', children: [{ text: url }] };
  Transforms.insertNodes(editor, image);
  Transforms.insertNodes(editor, {
    type: 'paragraph',
    children: [{ text: '' }],
  });
};

const clearEditor = (editor: Editor, initial: CustomElement[]) => {
  const point = { path: [0, 0], offset: 0 };
  editor.selection = { anchor: point, focus: point };
  editor.history = { redos: [], undos: [] };
  editor.children = initial;
};

const convertFromTextToRichText = (text: string | CustomElement[]) => {
  if (typeof text === 'string') {
    const result = text.split('\n').map((line) => {
      return { type: 'paragraph', children: [{ text: line }] } as CustomElement;
    });

    return result;
  } else {
    return text as CustomElement[];
  }
};

const insertTextAtCursor = (editor: Editor, tag: string) => {
  const tagNode = { text: tag };
  Transforms.insertNodes(editor, tagNode);
  ReactEditor.focus(editor); // Ensure the editor is focused after inserting
};

const handleTextReplacement = (editor: Editor) => {
  const { selection } = editor;

  if (selection && Range.isCollapsed(selection)) {
    const [start] = Range.edges(selection);

    // Iterate over possible distances (3 and 2) to check for patterns
    for (const distance of [2, 3]) {
      const before = Editor.before(editor, start, { unit: 'character', distance });

      if (before) {
        const beforeRange = { anchor: before, focus: start };
        const beforeText = Editor.string(editor, beforeRange);

        // Replace if pattern is found
        const emoji = Object.entries(emojiMap).find(([pattern]) =>
          beforeText.endsWith(pattern),
        )?.[1];

        if (emoji) {
          Transforms.select(editor, beforeRange);
          Transforms.delete(editor);
          Transforms.insertText(editor, emoji);
          return;
        }
      }
    }
  }
};

const tagRegex = /\{\{(.*?)\}\}/g;

const decorate = (entry: NodeEntry) => {
  const [node, path] = entry;
  const ranges = [];
  if (Text.isText(node)) {
    let match;
    const { text } = node;

    // Find matches using regex
    while ((match = tagRegex.exec(text)) !== null) {
      ranges.push({
        anchor: { path, offset: match.index },
        focus: { path, offset: match.index + match[0].length },
        tag: true,
      });
    }
  }
  return ranges as Range[];
};

const isBlockActive = (editor: Editor, format: ElementType) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => {
        if (!Editor.isEditor(n) && SlateElement.isElement(n)) {
          return n.type === format;
        }
        return false;
      },
    }),
  );

  return !!match;
};

const isListType = (format: ElementType) => {
  return LIST_TYPES.includes(format as ListType);
};

const toggleBlock = (editor: Editor, format: ElementType) => {
  const isActive = isBlockActive(editor, format);
  const isList = isListType(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && isListType(n.type),
    split: true,
  });

  const newProperties: Partial<SlateElement> = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  };

  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const isEmptyEditorValue = (data: any): boolean => {
  if (!data || !Array.isArray(data) || data.length === 0) {
    return true;
  }

  const checkEmptyNode = (node: any): boolean => {
    if (!node) return true;

    if (node.text && node.text.trim() !== '') {
      return false;
    }

    if (node.children && Array.isArray(node.children)) {
      return node.children.every(checkEmptyNode);
    }

    return true;
  };

  return data.every(checkEmptyNode);
};

export {
  getRawText,
  toggleFormat,
  insertLink,
  isFormatActive,
  getSelectedLink,
  isElementActive,
  isUrl,
  insertTemplate,
  isRichText,
  insertImage,
  isImageUrl,
  clearEditor,
  convertFromTextToRichText,
  insertTextAtCursor,
  handleTextReplacement,
  wrapLink,
  decorate,
  toggleBlock,
  isBlockActive,
  isEmptyEditorValue,
  isListType,
};
