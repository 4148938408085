export function NumberedListIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g id="numbered" transform="translate(-2.339 -2.375)">
        <g
          id="Rectangle_8501"
          data-name="Rectangle 8501"
          transform="translate(2.339 2.375)"
          stroke="currentColor"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <path
          id="format_list_numbered_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24"
          d="M140.561-842.1a.545.545,0,0,1-.4-.159.545.545,0,0,1-.159-.4.546.546,0,0,1,.159-.4.544.544,0,0,1,.4-.159h1.792v-1H141.5a.545.545,0,0,1-.4-.159.545.545,0,0,1-.159-.4.546.546,0,0,1,.159-.4.544.544,0,0,1,.4-.159h.851v-1h-1.792a.544.544,0,0,1-.4-.159.545.545,0,0,1-.159-.4.546.546,0,0,1,.159-.4.544.544,0,0,1,.4-.159h2.118a.77.77,0,0,1,.567.229.771.771,0,0,1,.229.568v1.124a.772.772,0,0,1-.229.568.77.77,0,0,1-.567.229.77.77,0,0,1,.567.229.772.772,0,0,1,.229.568v1.051a.772.772,0,0,1-.229.568.77.77,0,0,1-.567.229Zm.081-6.269a.62.62,0,0,1-.456-.187.621.621,0,0,1-.186-.456v-1.8a.772.772,0,0,1,.229-.568.77.77,0,0,1,.568-.229h1.556v-1h-1.792a.544.544,0,0,1-.4-.159.545.545,0,0,1-.159-.4.547.547,0,0,1,.159-.4.544.544,0,0,1,.4-.159h2.118a.77.77,0,0,1,.567.229.773.773,0,0,1,.229.568v1.649a.772.772,0,0,1-.229.568.77.77,0,0,1-.567.229h-1.557v1h1.792a.545.545,0,0,1,.4.159.545.545,0,0,1,.159.4.547.547,0,0,1-.159.4.544.544,0,0,1-.4.159Zm1.331-6.269a.546.546,0,0,1-.4-.159.546.546,0,0,1-.159-.4v-3.678h-.851a.545.545,0,0,1-.4-.159.545.545,0,0,1-.159-.4.547.547,0,0,1,.159-.4.544.544,0,0,1,.4-.159H141.9a.613.613,0,0,1,.452.182.614.614,0,0,1,.182.452v4.167a.546.546,0,0,1-.159.4A.544.544,0,0,1,141.973-854.637Zm4.2,9.947a.683.683,0,0,1-.5-.2.685.685,0,0,1-.2-.5.683.683,0,0,1,.2-.5.683.683,0,0,1,.5-.2h9.122a.682.682,0,0,1,.5.2.684.684,0,0,1,.2.5.683.683,0,0,1-.2.5.683.683,0,0,1-.5.2Zm0-5.653a.683.683,0,0,1-.5-.2.685.685,0,0,1-.2-.5.683.683,0,0,1,.2-.5.683.683,0,0,1,.5-.2h9.122a.682.682,0,0,1,.5.2.684.684,0,0,1,.2.5.683.683,0,0,1-.2.5.682.682,0,0,1-.5.2Zm0-5.653a.683.683,0,0,1-.5-.2.685.685,0,0,1-.2-.5.683.683,0,0,1,.2-.5.683.683,0,0,1,.5-.2h9.122a.682.682,0,0,1,.5.2.684.684,0,0,1,.2.5.683.683,0,0,1-.2.5.683.683,0,0,1-.5.2Z"
          transform="translate(-133.66 865.375)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
