import { memo } from 'react';

import { twMerge } from 'tailwind-merge';

import Copy from '../Copy';

export type BadgeProps = {
  theme?: 'purple' | 'primary' | 'danger';
  text: string;
};

const Badge = ({ theme = 'purple', text }: BadgeProps) => {
  return (
    <div
      className={twMerge(
        'rounded-[12px] px-2 text-center',
        theme === 'purple' && 'bg-v2violet drop-shadow-[0_2px_4px_rgba(110,80,255,0.2)] text-white',
        theme === 'primary' && 'bg-primary/20 text-primary',
        theme === 'danger' && 'bg-v2red/20 text-v2red',
      )}
    >
      <Copy noColor className="leading-[20px]" type="copy2" weight="medium">
        {text}
      </Copy>
    </div>
  );
};

export default memo<BadgeProps>(Badge);
