import { type ReactNode, useLayoutEffect } from 'react';

import { Controller, type FieldValues, type Path, useFormContext } from 'react-hook-form';

import { twMerge } from 'tailwind-merge';
import { Copy, ErrorMessage } from 'ui/atoms';
import { Dropdown, type TPopupPositions } from 'ui/molecules';

import { type TDropdownValueVariants, type TOption } from '../../../molecules/Dropdown/Dropdown';
import { get } from '../../../utils';

export interface _SelectProps {
  wrapperClassName?: string;
  className?: string;
  classNamePopup?: string;
  label?: ReactNode;
  placeholder?: ReactNode;
  options: Array<TOption>;
  isError?: boolean;
  errorMessage?: string;
  value?: TDropdownValueVariants;
  onChange?: (...event: any[]) => void;
  multiple?: boolean;
  dropdownPosition?: TPopupPositions;
  disabled?: boolean;
  withSearch?: boolean;
}

export type SelectProps = _SelectProps;

export function Select({
  wrapperClassName,
  className,
  label,
  placeholder,
  options,
  isError,
  errorMessage,
  value,
  onChange,
  multiple = false,
  dropdownPosition,
  disabled = false,
  classNamePopup,
  withSearch,
}: SelectProps) {
  return (
    <div className={twMerge('flex', wrapperClassName)}>
      {label && (
        <>
          <Copy className="mt-[0.5em] text-v2blueGray-600 truncate">{label}</Copy>
          <div className="grow" />
        </>
      )}
      <div data-testid="outvioui--select" className={twMerge('flex flex-col', className)}>
        <Dropdown
          classNamePopup={twMerge('max-width-[160px]', classNamePopup)}
          placeholder={placeholder}
          options={options}
          value={value}
          onChange={onChange}
          multiple={multiple}
          position={dropdownPosition}
          isError={isError}
          disabled={disabled}
          withSearch={withSearch}
        />
        {isError && errorMessage && (
          <ErrorMessage>{errorMessage ?? ('' as ReactNode)}</ErrorMessage>
        )}
      </div>
    </div>
  );
}

export type _SelectRhfProps<T> = {
  className?: string;
  wrapperClassName?: string;
  name: Path<T>;
  placeholder?: ReactNode;
  options: Array<TOption>;
  label?: ReactNode;
  multiple?: boolean;
  dropdownPosition?: TPopupPositions;
  disabled?: boolean;
  classNamePopup?: string;
  withSearch?: boolean;
  initialValue?: TDropdownValueVariants;
};

export type SelectRhfProps<T extends FieldValues> = _SelectRhfProps<T>;

export function SelectRhf<T extends FieldValues>({
  name,
  multiple = false,
  dropdownPosition,
  withSearch,
  classNamePopup,
  initialValue,
  ...otherProps
}: SelectRhfProps<T>) {
  const {
    unregister,
    getFieldState,
    formState: { errors },
  } = useFormContext<T>();

  useLayoutEffect(() => {
    // const nestedValue = defaultValues ? get(defaultValues, name) : null;

    // if (nestedValue) {
    //   setValue(name, nestedValue ?? (null as PathValue<T, Path<T>>));
    // }

    return () => {
      const { isDirty } = getFieldState(name as Path<T>);
      unregister(name as Path<T>, { keepDirty: true, keepDefaultValue: true, keepValue: isDirty });
    };
  }, [name, unregister, getFieldState]);

  return (
    <Controller
      name={name}
      defaultValue={initialValue}
      render={({ field: { onChange, value, name: alias } }) => {
        const handleOnChange = (newValue: TDropdownValueVariants) => {
          let newSelectState;

          if (!multiple) {
            newSelectState = newValue;
          } else if (value?.includes(newValue)) {
            newSelectState = value.filter(
              (selectedValue: TDropdownValueVariants) => selectedValue !== newValue,
            );
          } else {
            newSelectState = [...((value || []) as any)];
            newSelectState.push(newValue);
          }

          onChange(newSelectState);
        };

        const fieldError = get(errors, alias);

        return (
          <Select
            withSearch={withSearch}
            classNamePopup={classNamePopup}
            dropdownPosition={dropdownPosition}
            onChange={handleOnChange}
            isError={!!fieldError}
            errorMessage={fieldError?.message as string}
            value={value}
            multiple={multiple}
            {...otherProps}
          />
        );
      }}
    />
  );
}

export default Select;
