import { useRef } from 'react';

import { useScrollText } from '../../hooks/useScrollText';
import { TOption } from './Dropdown';
import { DropdownItem } from './DropdownItem';

const DefaultOption = ({
  option,
  selected,
  preselected,
}: {
  option?: TOption;
  selected?: boolean;
  preselected?: boolean;
}) => {
  const textBoxRef = useRef<HTMLDivElement>(null);
  const triggerBoxRef = useRef<HTMLDivElement>(null);

  useScrollText(textBoxRef, triggerBoxRef);
  return (
    <div className="group" ref={triggerBoxRef}>
      <DropdownItem
        className={preselected && !selected ? 'bg-v2blueGray-200/40' : ''}
        icon={option?.icon}
        selected={selected}
        disabled={option?.disabled}
      >
        <div ref={textBoxRef} className="whitespace-nowrap truncate">
          <span className="group-hover:inline-block">{option?.label}</span>
        </div>
      </DropdownItem>
    </div>
  );
};

export { DefaultOption };
