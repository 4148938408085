import { ButtonHTMLAttributes, memo, useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

export interface ActionIconProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
  toggled?: boolean;
  theme?: ActionIconTheme;
  size?: ActionIconSize;
}

export enum ActionIconSize {
  xs = 'xs',
  default = 'default',
}

export enum ActionIconTheme {
  gray200 = 'gray200',
  borderGray200 = 'borderGray200',
  light = 'light',
  blackBg = 'blackBg',
}

function ActionIconBase({
  active,
  toggled,
  className,
  theme,
  children,
  size,
  ...otherProps
}: ActionIconProps) {
  const _className = useMemo(() => {
    return twMerge(
      'text-v2blueGray-500',
      'flex items-center justify-center',
      'transition-colors duration-300',

      (() => {
        switch (size) {
          case ActionIconSize.xs:
            return 'h-[18px] w-[18px] rounded';
          default:
            return 'h-9 w-9 text-2xl rounded-10';
        }
      })(),
      (() => {
        switch (theme) {
          case ActionIconTheme.gray200:
            return twMerge(`hover:bg-v2blueGray-200`, active && 'bg-v2blueGray-200');
          case ActionIconTheme.light:
            return twMerge(
              `hover:bg-v2blueGray-200/20 text-white`,
              active && 'bg-v2blueGray-200/20',
            );
          case ActionIconTheme.borderGray200:
            return twMerge(
              `border border-v2blueGray-200 hover:bg-v2blueGrayHover-200`,
              active && 'bg-v2blueGrayHover-200',
            );
          case ActionIconTheme.blackBg:
            return twMerge(
              `text-v2blueGray-0 hover:bg-v2blueGray-700`,
              active && 'bg-v2blueGray-700',
              toggled && 'bg-v2blueGray-0 hover:bg-v2blueGray-0 text-v2blueGray-800',
            );
          default:
            return twMerge(`hover:bg-v2blueGray-100 `, active && 'bg-v2blueGray-100');
        }
      })(),
      otherProps.disabled && 'cursor-not-allowed',
      className,
    );
  }, [open, className, theme, active, toggled, size, otherProps.disabled]);

  return (
    <button className={_className} {...otherProps}>
      {children}
    </button>
  );
}

export const ActionIcon = memo(ActionIconBase);
