import { memo, useCallback } from 'react';

import { isKeyHotkey } from 'is-hotkey';
import { useIntl } from 'localization';
import { BaseEditor, Editor, Element, Range, Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';
import { Editable, ReactEditor, RenderElementProps, RenderLeafProps, Slate } from 'slate-react';
import { twMerge } from 'tailwind-merge';

import Leaf from './Leaf';
import LongTextWrap from './LongTextWrap';
import RenderElement from './RenderElement';
import { hotKeys } from './constants';
import Toolbar from './toolbar/Toolbar';
import { CustomElement, CustomText, Format } from './types';
import { decorate, handleTextReplacement, toggleFormat } from './utils';

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

export type ShortCuts =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'link'
  | 'emoji'
  | 'image'
  | 'numbered-list'
  | 'bulleted-list';

type RichTextEditorProps = {
  onBlur?: (value: { textJson: CustomElement[] }) => void;
  placeholder?: string;
  actionElement?: JSX.Element;
  additionalToolbarButtons?: JSX.Element;
  visibleToolbarShortcuts?: ShortCuts[];
  hidddenToolbarShortcuts?: ShortCuts[];
  className?: string;
  onChange: (value: CustomElement[]) => void;
  value: CustomElement[];
  editor: Editor;
};

const RichTextEditor = memo(
  ({
    visibleToolbarShortcuts = [],
    hidddenToolbarShortcuts = [],
    actionElement,
    additionalToolbarButtons,
    placeholder,
    className,
    onChange,
    value,
    editor,
    onBlur,
  }: RichTextEditorProps) => {
    const { formatMessage } = useIntl();

    const renderElement = useCallback(
      (props: RenderElementProps) => <RenderElement {...props} />,
      [],
    );
    const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);

    const isEditorEmpty =
      editor.children.length === 1 &&
      !['numbered-list', 'bulleted-list'].includes(
        Element.isElement(editor.children[0]) ? editor.children[0].type : '',
      );

    return (
      <Slate
        editor={editor}
        initialValue={value}
        onChange={(newValue) => {
          onChange(newValue as CustomElement[]);
          handleTextReplacement(editor);
        }}
      >
        <LongTextWrap
          className={twMerge(
            'flex-1 flex flex-col max-h-full min-h-[88px] overflow-y-auto',
            className,
          )}
        >
          <Editable
            className="flex-1 h-full w-full break-words outline-none"
            onBlur={() => {
              onBlur?.({ textJson: value });
            }}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            decorate={decorate}
            onKeyDown={(event) => {
              const { selection } = editor;

              if (selection && Range.isCollapsed(selection)) {
                const { nativeEvent } = event;
                const [linkNode] = Editor.nodes(editor, {
                  match: (n) => Element.isElement(n) && n.type === 'link',
                });

                if (linkNode) {
                  if (isKeyHotkey('left', nativeEvent)) {
                    event.preventDefault();
                    Transforms.move(editor, { unit: 'offset', reverse: true });
                    return;
                  }
                  if (isKeyHotkey('right', nativeEvent)) {
                    event.preventDefault();
                    Transforms.move(editor, { unit: 'offset' });
                    return;
                  }
                }

                for (const hotkey of Object.keys(hotKeys) as Array<keyof typeof hotKeys>) {
                  if (isKeyHotkey(hotkey, event)) {
                    event.preventDefault();
                    const mark = hotKeys[hotkey] as Format;
                    toggleFormat(editor, mark);
                  }
                }
              }
            }}
            placeholder={
              isEditorEmpty ? placeholder || formatMessage({ id: 'desk-app.write-message' }) : ''
            }
            spellCheck
          />
        </LongTextWrap>

        <Toolbar
          visibleToolbarShortcuts={visibleToolbarShortcuts}
          hidddenToolbarShortcuts={hidddenToolbarShortcuts}
          additionalToolbarButtons={additionalToolbarButtons}
          actionElement={actionElement}
        />
      </Slate>
    );
  },
);

export default RichTextEditor;
