import { Fragment, ReactNode } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

const DialogContainer = ({
  children,
  isOpen,
  handleClose,
  className,
  zIndex = 10,
  wrapperClassName = 'p-4',
  disableMaxWidth,
  afterLeave,
}: {
  wrapperClassName?: string;
  className?: string;
  children: ReactNode;
  isOpen: boolean;
  handleClose: () => void;
  zIndex?: number;
  disableMaxWidth?: boolean;
  afterLeave?: () => void;
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment} afterLeave={afterLeave}>
      <Dialog as="div" style={{ zIndex }} className="relative" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div style={{ zIndex }} className="fixed inset-0 bg-v2blueGray-800/30" />
        </Transition.Child>

        <div style={{ zIndex }} className="fixed inset-0 overflow-y-auto">
          <div
            className={twMerge(
              'flex min-h-full items-center justify-center text-center',
              wrapperClassName,
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={twMerge(
                  !disableMaxWidth && 'max-w-md',
                  'w-full transform rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all',
                  className,
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export { DialogContainer };
