import {
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  Placement,
  UseFloatingOptions,
  flip,
  offset,
  shift,
  size,
  useFloating,
} from '@floating-ui/react-dom';
import { ExpandMore, Search } from 'icons';
import { FormattedMessage } from 'localization';
import { twMerge } from 'tailwind-merge';
import { Copy, TextInput } from 'ui/atoms';

import useClickAway from '../../hooks/useClickAway';
import { DefaultDropdownValue } from './DefaultDropdownValue';
import { DefaultOption } from './DefaultOption';
import { DropdownPopup, type TPopupPositions } from './DropdownPopup';

export interface ValueComponentProps<Data = any> {
  placeholder?: IDropdownProps['placeholder'];
  value?: Data;
  open: boolean;
  multiple?: boolean;
  isError?: boolean;
  disabled?: boolean;
  className?: string;
}

export interface OptionComponentProps {
  option?: TOption;
  selected?: boolean;
  preselected?: boolean;
}

export function Dropdown({
  open: customOpen,
  setOpen: customSetOpen,
  placeholder,
  value,
  withSearch,
  onChange = () => null,
  options: initialOptions = [],
  className,
  disabled = false,
  classNamePopup,
  classNameValue,
  multiple = false,
  Value = DefaultDropdownValue,
  Option = DefaultOption,
  position = 'bottom-start',
  floatingOptions = {
    placement: position as Placement,
    middleware: [
      offset(4),
      flip(),
      shift(),
      size({
        apply({ availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: '100%',
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight - 24}px`,
          });
        },
      }),
    ],
  },
  isError,
}: IDropdownProps) {
  const [_open, _setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<TOption[]>(initialOptions);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const [search, setSearch] = useState('');

  const open = customOpen ?? _open;
  const setOpen = customSetOpen || _setOpen;
  const ref = useRef<HTMLDivElement | null>(null);
  const optionRefs = useRef<(HTMLDivElement | null)[]>([]);

  useClickAway(ref, () => setOpen(false));

  const valueObj = useMemo(() => {
    if (multiple && Array.isArray(value)) {
      return value.map((val) => {
        const option = options.find((option) => option.value === val);

        if (option) {
          return option;
        }

        return { label: val, value: val };
      });
    }
    const option = options.find((option) => option.value === value);
    return option || { label: '', value: '' };
  }, [multiple, value, options]);

  const searchButtonValue = useMemo(() => {
    if (multiple && Array.isArray(valueObj)) {
      return valueObj.map((option) => option.label).join(', ');
    }
    return (valueObj as TOption)?.label || '';
  }, [multiple, value, valueObj]);

  const handleChange = useCallback(
    (value: any) => {
      onChange(value);
      if (!multiple) {
        setOpen(false);
      }
    },
    [multiple, options, onChange],
  );

  const { refs, floatingStyles } = useFloating(floatingOptions);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchValue = event.target.value.toLowerCase();
      const filteredOptions = initialOptions.filter((option) =>
        option.label.toLowerCase().includes(searchValue),
      );
      setOptions(filteredOptions);
      setSearch(event.target.value);

      const newIndex =
        filteredOptions.findIndex((option) => option.label === searchButtonValue) || 0;

      const finalIndex = newIndex !== -1 ? newIndex : 0;

      setFocusedIndex(finalIndex);
    },
    [initialOptions],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (!open) return;

      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setFocusedIndex((prev) => (prev === null || prev === options.length - 1 ? 0 : prev + 1));
          break;
        case 'ArrowUp':
          e.preventDefault();
          setFocusedIndex((prev) => (prev === 0 || prev === null ? options.length - 1 : prev - 1));
          break;
        case 'Enter':
          e.preventDefault();
          if (focusedIndex !== null && !!options?.length) handleChange(options[focusedIndex].value);
          break;
        case 'Escape':
        case 'Tab':
          setOpen(false);
          setFocusedIndex(null);
          break;
      }
    },
    [open, focusedIndex, options],
  );

  useEffect(() => {
    setOptions(initialOptions);
    setSearch(searchButtonValue);
  }, [initialOptions, value]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      optionRefs.current[focusedIndex || 0]?.scrollIntoView({
        block: 'nearest',
      });
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [open]);

  useEffect(() => {
    if (open && focusedIndex !== null && optionRefs.current[focusedIndex]) {
      optionRefs.current[focusedIndex]!.scrollIntoView({
        block: 'nearest',
        behavior: 'auto',
      });
    }
  }, [focusedIndex]);

  const handleOpen = useCallback(() => {
    if (!open) {
      const newIndex = initialOptions.findIndex((option) =>
        typeof searchButtonValue === 'string'
          ? searchButtonValue?.includes(option.label)
          : option.label === searchButtonValue,
      );
      const finalIndex = newIndex !== -1 ? newIndex : 0;

      setFocusedIndex(finalIndex);
      setSearch('');
      setOptions(initialOptions);
    } else {
      setSearch(searchButtonValue);
    }
    setOpen((state) => !state);
  }, [open, searchButtonValue, initialOptions]);

  return (
    <div
      ref={(element) => {
        refs.setReference(element);
        ref.current = element;
      }}
      className={twMerge('relative font-inter z-1', open && 'z-[2]', className)}
    >
      {!withSearch && (
        <button
          disabled={disabled}
          onKeyDown={handleKeyDown}
          type="button"
          className="w-full"
          onClick={(event) => {
            event.preventDefault();
            // event.stopPropagation();
            handleOpen();
          }}
          onBlur={() => {
            setFocusedIndex(null);
          }}
        >
          <Value
            className={classNameValue}
            open={open}
            placeholder={placeholder}
            value={valueObj}
            multiple={multiple}
            isError={isError}
            disabled={disabled}
          />
        </button>
      )}

      {withSearch && (
        <div
          onKeyDown={handleKeyDown}
          tabIndex={0}
          onClick={handleOpen}
          onBlur={() => {
            setSearch(value);
          }}
        >
          <TextInput
            className="!cursor-pointer"
            name=""
            icon={
              open ? (
                <Search className="w-5 h-5 text-v2blueGray-550" />
              ) : (
                <ExpandMore className="w-5 h-5 text-v2blueGray-550" />
              )
            }
            iconPosition="right"
            onChange={handleSearchChange}
            value={(open && search) || (!open && (search || value)) ? search : ''}
            placeholder={value || placeholder}
            isError={isError}
          />
        </div>
      )}

      <DropdownPopup
        ref={refs.setFloating}
        style={floatingStyles}
        open={open}
        className={twMerge(
          'static w-full gap-[5px] flex flex-col max-h-60 overflow-auto mt-0',
          classNamePopup,
        )}
        position={position}
      >
        {options && options.length > 0 ? (
          options.map((option, index) => {
            let selected = false;
            if (multiple && Array.isArray(value)) {
              selected = value.includes(option.value);
            }

            if (!Array.isArray(value) && value !== undefined) {
              selected = option.value === value;
            }

            return (
              <div
                className="hide-cursor"
                ref={(el) => (optionRefs.current[index] = el)}
                key={`${option.value}${index}`}
                onMouseEnter={() => setFocusedIndex(index)}
                onClick={(event) => {
                  event.preventDefault();

                  if (option.disabled) {
                    return;
                  }

                  handleChange(option.value);
                }}
              >
                <Option option={option} selected={selected} preselected={index === focusedIndex} />
              </div>
            );
          })
        ) : (
          <div className="my-[6px] text-v2blueGray-500 text-center">
            <Copy type="copy2">
              <FormattedMessage id="desk-app.no-available-options" />
            </Copy>
          </div>
        )}
      </DropdownPopup>
    </div>
  );
}

export type TOption = {
  label: any;
  value: any;
  disabled?: boolean;
  icon?: ReactNode;
  [param: string]: any;
};

export type TDropdownValueVariants = any | any[];
export type IDropdownProps = {
  classNameValue?: string;
  floatingOptions?: UseFloatingOptions;
  onChange?: (value: any) => void;
  value?: TDropdownValueVariants;
  options?: TOption[];
  multiple?: boolean;
  placeholder?: ReactNode;
  Value?: FunctionComponent<ValueComponentProps>;
  Option?: FunctionComponent<OptionComponentProps>;
  className?: string;
  classNamePopup?: string;
  position?: TPopupPositions;
  disabled?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  open?: boolean;
  isError?: boolean;
  withSearch?: boolean;
};

export default memo<IDropdownProps>(Dropdown);
